<template>
    <div style="margin-bottom:2rem;">
        <div class="title1">
            <p class="title_top">TRADE NEWS</p>
            <p class="title_content">行业新闻</p>
        </div>
        <div style="margin-bottom:2rem;">
            <div @click="go(item.id)" :class="'box_shadow col7-3 flex center wrap '+(index!=(data.length-1)?'item':'')" v-for="(item,index) in data" :key="item.id">
                <div class="col3 flex center" style="    
                    max-height: 222px;
                    height: 70%;
                    padding: 20px 0px;
                    min-height: 100px;">
                        <img  style="    
                                width: 100%;
                                max-width: 285px;
                                height: inherit;
                                max-height: inherit;
                                min-height: inherit;" v-lazy="require('../../assets/news/industry/'+item.url)">
                </div>
                <div class="col7 flex center" style="text-align:left;min-height:273px">
                    <div class="col9" style="font-size:0.9rem;margin:0 auto">
                        <div class="space_content" style="font-size:1rem;margin-bottom: 0px;font-weight:bold;margin-top: 10px;">
                                {{item.title}}
                        </div>
                        <div class="space_content" style="font-size:1rem;text-align:right;color:#a7a7a7">
                                {{item.time}}
                        </div>
                        <div class="space_content" :key="index" v-for="(content,index) in item.content" :style="(index!=(content.length-1))?'margin-bottom: 10px;':''">
                            {{content}}
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <More></More>
    </div>
</template>
<script>
import More from "@/components/More.vue"
export default {
    components:{More},
    data(){
        return{
            data:[
                {id:1,url:'1-1.jpg',time:'2021-05-21',title:'国家禁毒办：将整类合成大麻素类物质列入管制',content:['2021年5月11日，国家禁毒委员会办公室举行的新闻发布会介绍，公安部、国家卫生健康委员会和国家药品监督管理局联合发布《关于将合成大麻素类物质和氟胺酮等18种物质列入〈非药用类麻醉药品和精神药品管制品种增补目录〉的公告》，决定正式整类列管合成大麻素类新精神活性物质，并新增列管氟胺酮等18种新精神活性物质。自2021年7月1日起施...']},
                {id:2,url:'2-1.jpg',time:'2017-05-01',title:'伤后多长时间可以进行伤残鉴定？',content:['《道路交通事故受伤人员伤残评定（GB18667-2002）》中第3.2条规定“评定时机应以事故直接所致的损伤或确因损伤所致的并发症治疗终结为准。对治疗终结意见不一致时，可由办案机关组织有关专业人员进行鉴定，确定其是否治疗终结。”《道路交通事故受伤人员伤残评定（GB18667-2002）》中第2.7条规定“治疗终结”是指“临床医学一般原则所承认的临床效果稳定。”由于损伤部位及性质不同，“治疗终结”的时间也不一样。具体如下...']},
            ]
        }
    },
    methods:{
        go(id){
            let pathStr = '/news/industrynews/'+id
            this.$store.commit('set_path',{path:pathStr,query:{}})
        }
    }
}
</script>
<style scoped>
.item{
    border-bottom: 1px solid #dddddd;
    margin-bottom: 2rem;
}
</style>